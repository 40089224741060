import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import Text from './Text';
import { theme } from '../style/theme';
import { device, getWidth } from './Devices';
import { GatsbyImage } from 'gatsby-plugin-image';

import 'swiper/css';
import "swiper/css/navigation";
import 'bootstrap/dist/css/bootstrap.min.css';

const Carousel = styled(Swiper)`
    margin: 5% 0 !important;
    padding-left: 3% !important;
    padding-right: 3% !important;
    width: 87%;

    .swiper-slide {
        align-items: center;
        border: 1px solid ${props => props.color};
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        height: 340px;
        padding: 5% 1.2% 1.2% 1.2%;

        span{
            text-align: center;
            line-height: 15.6pt;
            white-space: pre-line;
        }

        .minicarousel-img-container{
            align-items: center;
            display: flex;
            height: 50%;
            justify-content: center;
            width: 100%;
        }
    }

    .swiper-button-prev {
        left: 0 !important;
        top: 50% !important;
        height: 35px !important;
        width: 35px !important;
    }

    .swiper-button-next{
        top: 50% !important;
        right: 0% !important;
        height: 35px !important;
        width: 35px !important;
    }

    .swiper-button-prev::after, .swiper-button-next::after {
        align-items: center;
        border-radius: 60px;
        background-color: ${props => props.color} !important;
        display: flex;
        justify-content: center;
        font-size: 0.8rem !important;
        height: 35px !important;
        width: 35px !important;
    }

    .MiniCarouselText{
        width: 90%;
    }

    @media ${device.tablet} {
        width: 100%;

        .swiper-button-next{
            right: 0% !important;
        }
    }

    @media ${device.mobileL}{
        .MiniCarouselText{
            font-size: 13pt;
        }
    }
`;

const IdCard = styled.span`
            align-items: center;
            background-color: ${props => props.color};
            border-radius: 60px;
            color: white;
            font-family: ${theme.fonts.type.numberSlider};
            font-size: 4rem;
            display: flex;
            justify-content: center;
            height: 110px;       
            line-height: initial !important;
            margin-top: -6%;
            margin-bottom: 4%;
            width: 110px;

            @media ${device.tablet}{
                font-size: 3rem;
            }

            @media ${device.mobileM}{
                height: 100px;
                width: 100px;
            }

            @media ${device.mobileS}{
                height: 90px;
                width: 90px;
            }
`;


const TitleCard = styled.h2`
            color: ${props => props.color};
            font-family: ${theme.fonts.type.h2};
            font-size: 1.1rem;

            @media ${device.tablet}{
                font-size: ${theme.fonts.size.m.h2};
                text-align: center;   
            }

            @media ${device.mobileL}{
                font-size: 14pt;
            }

            
`;


const MiniCarousel = ( { data, slidesPerView, images, className }) => {
    
    const [spaceBetween, setSpaceBetween] = useState(30);
    const [slides, setSlidesPerView] = useState(slidesPerView);

    useEffect(() => {
        const w = getWidth();
        if (w <= 900 && w > 560) {
            setSpaceBetween(22);
            setSlidesPerView(2)
        }else if(w <= 560){
            setSpaceBetween(20);
            setSlidesPerView(1);
        }
    }, []);


    return (
        <Carousel className={className} navigation={true} modules={[Navigation]} slidesPerView={slides} spaceBetween={spaceBetween} color={data[0].color} >
            {
                data.map((info, index) => (
                    <SwiperSlide key={index} style={{ border: images === undefined ? '1px solid #005C82' : `1px solid ` + info.color }}>
                            {
                            (images === undefined ? 
                                <IdCard className='minicarousel-id' color={info.color} key={info.id}>{info.id}</IdCard>
                                :
                                <div className={'minicarousel-img-container'}>
                                    <GatsbyImage image={images[index].childImageSharp.gatsbyImageData} alt={`Ingredient #` + info.id} key={info.id} className={className + `-img`} placeholder='blurred'/>
                                </div>
                                )
                            }
                            
                            <TitleCard color={info.color}>{info.title}</TitleCard>
                            <Text className={'MiniCarouselText'} text={info.description} />
                    </SwiperSlide>
                ))
            }

        </Carousel>

    );
}

export default MiniCarousel;