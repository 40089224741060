import * as React from "react"
import Layout from '../components/Layout';
import MainButton from "../components/MainButton";
import MiniCarousel from "../components/MiniCarousel";
import styled from 'styled-components';
import Text from '../components/Text';
import Title from '../components/Title';
import { device } from "../components/Devices";
import { graphql, useStaticQuery } from "gatsby";
import { theme } from '../style/theme';


const Section = styled.section`
    display: flex;
    flex-direction: row;
    padding: ${theme.paddings.main};
    padding-top: 100px !important;
    margin-top: 4%;
    width: 100%;

    @media ${device.laptopM}{
        padding: ${theme.paddings.mainLaptopM}
    }

    @media ${device.laptop}{
        padding: ${theme.paddings.mainLaptop}
    }

    @media ${device.tablet}{
        flex-direction: column;
        margin-top: 0;
        padding: ${theme.paddings.mainTablet};

        .nosotros-minicarousel .swiper-slide {
            justify-content: center;
            padding: 0 2% !important;
            height: 290px;
        }

    }

    @media ${device.mobileL}{
        padding-top: ${theme.paddings.topMobile};

        .nosotros-button{
            position: relative !important;
            top: 0;
        }
    }
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;

    .nosotros-title {
        margin-bottom: 8%;
    }

    @media ${device.tablet}{
        align-items: left;
        width: 50%;

        .nosotros-title {
            text-align: left;
        }
    }

    @media ${device.mobileL}{
        width: 70%;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;

    .nosotros-minicarousel{
        width: 100%;
    }

    .nosotros-minicarousel .swiper-button-prev::after, 
    .nosotros-minicarousel .swiper-button-next::after{
        background-color: #005C82 !important;
    }

    @media ${device.tablet}{
        width: 100%;
    }
`;

const StyledButton = styled(MainButton)`
    position: relative;
    top: 0 !important;

    @media ${device.mobileL}{
        width: 70%;
    }
`;

const TextWrapper = styled.div`
    column-count: 2;
    column-gap: 4%;
    padding-left: 2.5%;
    white-space: inherit;
    width: 100%;

    span {
        white-space: inherit;
    }

    h2 {
        color: ${theme.colors.text.h2};
        font-family: ${theme.fonts.type.h2};
        font-size: 1rem;
        margin-top: 5%;
    }

    @media ${device.tablet}{
        column-count: 1;
        margin-top: 4%;
        line-height: 1;
        padding-left: 0;
        white-space: pre-line;
        width: 100%;

        h2 {
            font-size: 0.9rem;
        }

        .nosotros-text {
            font-size: 1rem !important;
            line-height: 1;
        }
    }

    @media ${device.mobileL} {
        padding: 0;
        
        .nosotros-text {
            font-size: 0.75rem;
        }
    }
` ;


const NosotrosPage = () => {

    const data = useStaticQuery(graphql`
    query{
        site {
            siteMetadata {
              nosotrosSite {
                color
                description
                id
                title
              }
            }
          }
    }
    `);

    return (
        <main>
        <Layout>
            <Section>
                <LeftContainer>
                    <Title className='nosotros-title' color={theme.colors.text.main} title='Awana, Nutrición Animal'/>
                    <StyledButton className='nosotros-button' color={theme.colors.background.main} text='CONOCÉ MÁS DE AWANA'/>
                </LeftContainer>
                <RightContainer>
                    <TextWrapper>
                    <Text className={'nosotros-text'} 
                        text={`Somos un grupo de amigos a los que nos conecta el amor por nuestras mascotas. Con una amplia experiencia aportada por muchos años de trabajo en el rubro de la alimentación animal, abarcando tanto lo comercial y la distribución como lo veterinario y lo técnico, nos unimos bajo el nombre de Awana para combinar nuestros conocimientos y desarrollar el mejor alimento para nuestros compañeros incondicionales.
                        Antes de empezar a idear este alimento, como padres de mascotas, era nuestra obligación buscar la mejor opción nutricional para ellas. Siempre buscábamos productos elaborados con ingredientes que conociéramos, de primera calidad, fuentes nobles de proteínas, productos fáciles de compender en su comunicación. 
                        Siempre buscamos también el lado humano del producto por encima del comercial, pero no tuvimos oportunidad de encontrar nada parecido en el mercado nacional.
                        Con esta pasión e inspirados en nuestras propias mascotas, encaramos el sueño de crear un alimento propio para darles lo que merecían, pero nadie ofrecía. Esto nos llevó a una mayor consciencia por el cuidado de los detalles, no sólo desde lo nutricional, sino también teniendo en cuenta el mundo que nos rodea. Así nació Livra, un alimento hecho con todo el cariño para nuestros compañeros de cuatro patas y que queremos compartir con ustedes. Es nuestra meta también que la comercialización de Livra se realice bajo una política honesta y práctica, con compromiso a largo plazo,generando de esta forma un mercado sustentable y sin letras chicas.`}/>
                    <h2>LA CALIDAD DE LOS INGREDIENTES</h2>
                    <Text className={'nosotros-text'}
                        text={`Fabricamos nuestros productos atendiendo los cuatro puntos que consideramos fundamentales en cuanto a controles de calidad:`}
                    />
                    </TextWrapper>
                    <MiniCarousel className={'nosotros-minicarousel'} data={data.site.siteMetadata.nosotrosSite} slidesPerView={2}></MiniCarousel>
                </RightContainer>
            </Section>
        </Layout>
        </main>
  );
}

export default NosotrosPage;

export { Head } from "../components/Head";